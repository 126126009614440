import React from 'react';
import { InlineWidget } from "react-calendly";

function Calendly() {
  return (
    <div style={{ height: '700px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <InlineWidget 
        url="https://calendly.com/steelify-info/15min" 
        styles={{
          height: '100%',
          width: '100%',
        }}
      />
    </div>
  );
}

export default Calendly;
