import React from "react";
import "./capabilities.css";

const capabilitiesData = [
  { title: "Welding", image: "https://yenastorage.blob.core.windows.net/steelify/steelify_welding_square.png" },
  { title: "CNC Machining", image: "https://yenastorage.blob.core.windows.net/steelify/steelify_CNC_square.png" },
  { title: "Bending", image: "https://yenastorage.blob.core.windows.net/steelify/steelify_bending_square.png" },
  { title: "Laser Cutting", image: "https://yenastorage.blob.core.windows.net/steelify/steelify_laser_square.png" },
];

const Capabilities = () => {
  return (
    <div className="capabilities-container">
      <h2 className="capabilities-title">Capabilities</h2>
      {capabilitiesData.map((capability, index) => (
        <div key={index} className="capability-item">
          <div className="capability-icon-container">
            <img src={capability.image} alt={capability.title} className="capability-icon" />
          </div>
          <p className="capability-title">{capability.title}</p>
        </div>
      ))}
    </div>
  );
};

export default Capabilities;
