import React, { useState } from "react";
import "./completedWorks.css";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const sectors = [
  { name: "Welding", images: [
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test1.jpg",
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test2.jpg",
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test3.jpg"
  ]},
  { name: "CNC Machine", images: [
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test4.jpg",
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test5.jpg"
  ]},
  { name: "Bending", images: [
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test6.jpg",
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test7.jpg"
  ]},
  { name: "Laser Cutting", images: [
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test8.jpg",
    "https://yenastorage.blob.core.windows.net/steelify/steelify_image_test9.jpg"
  ]}
];

const CompletedWorks = () => {
  const [selectedSector, setSelectedSector] = useState(0);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleNext = () => {
    if (selectedImage < sectors[selectedSector].images.length - 1) {
      setSelectedImage((prev) => prev + 1);
    } else {
      if (selectedSector < sectors.length - 1) {
        setSelectedSector((prev) => prev + 1);
        setSelectedImage(0); // Yeni sektöre geçtiğinde resimleri sıfırla
      }
    }
  };

  const handlePrevious = () => {
    if (selectedImage > 0) {
      setSelectedImage((prev) => prev - 1);
    } else {
      if (selectedSector > 0) {
        setSelectedSector((prev) => prev - 1);
        setSelectedImage(sectors[selectedSector - 1].images.length - 1); // Önceki sektöre geçtiğinde son resmi göster
      }
    }
  };

  const handleSectorChange = (index) => {
    setSelectedSector(index);
    setSelectedImage(0);  // Sektör değiştirildiğinde resmi sıfırla
  };

  return (
    <div className="completed-works-container">
      <div className="sectors">
        <h2>Completed Works</h2>
        <p>Sectors</p>
        <ul>
          {sectors.map((sector, index) => (
            <li
              key={index}
              className={selectedSector === index ? "active-sector" : ""}
              onClick={() => handleSectorChange(index)}
            >
              {sector.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="image-display">
        <button className="nav-button prev-button" onClick={handlePrevious}>
          <LeftOutlined />
        </button>
        <img
          src={sectors[selectedSector].images[selectedImage]}
          alt={sectors[selectedSector].name}
          className="completed-work-image"
        />
        <button className="nav-button next-button" onClick={handleNext}>
          <RightOutlined />
        </button>
      </div>
    </div>
  );
};

export default CompletedWorks;
