import React from "react";
import "./howSteelifyWorks.css";

const HowSteelifyWorks = () => {
  return (
    <div className="how-steelify-works-container">
      <div className="how-steelify-content">
        <div className="how-steelify-left">
          <h2>How do I get started with Steelify?</h2>
          <p>
            Steelify operates through a streamlined and transparent process to
            meet your manufacturing needs. It all starts with you submitting
            your requirements, including technical drawings, through the
            platform. Once your request is uploaded, Steelify reviews the
            details and provides a competitive quote based on your
            specifications. Throughout the process, you will stay updated on the
            status of your project, ensuring you are always informed.
          </p>
        </div>
        <div className="how-steelify-right">
          <video className="steelify-video" controls>
            <source
              src="https://yenastorage.blob.core.windows.net/steelify/steelify_video_how_steelify_works.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          
        </div>
      </div>
      <div className="steelify-columns">
        <div className="steelify-column">
          <h3>What happens after I accept the quote?</h3>
          <p>
            Once the quote is accepted, Steelify’s network of trusted local
            manufacturers begins production. Strict quality control measures are
            in place, with thorough inspections and precision measurements taken
            to ensure your parts meet the highest standards. Whether it’s
            welding, machining, or other fabrication services, each step is
            monitored closely to maintain the quality and accuracy of your
            order. Documentation, such as certificates and reports, is provided
            to guarantee that the necessary industry standards are met.
          </p>
        </div>
        <div className="steelify-column">
          <h3>How will I receive my order and track its progress?</h3>
          <p>
            When production is complete, your order is securely packaged and
            prepared for shipment. You can track your shipment in real time as
            it makes its way to your designated location. Steelify ensures
            timely delivery, and with all documentation in hand, your order
            arrives ready for use. This entire process reflects Steelify’s
            commitment to providing a reliable, transparent, and high-quality
            manufacturing experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowSteelifyWorks;
