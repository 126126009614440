import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, message, Switch, Row, Col, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons"; // Kilit ikonu
import FileUpload from "./fileUpload";
import PhotoUpload from "./photoUpload";
import LoginModal from "../../Modals/loginModal";
import useQuoteDetails from "../../Hooks/useQuoteDetails";
import "./getQuoteForm.css";

const { Title } = Typography;

const GetQuoteForm = ({ onSubmit, order_id, isPreFilled = false }) => {
  const [form] = Form.useForm();
  const [isTargetPrice, setIsTargetPrice] = useState(false); // Track if Target Price is selected
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false); // Manage login modal visibility
  const userInfo = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.token);
  const { fileList, photoList, setFileList, setPhotoList } = useQuoteDetails(
    order_id,
    isPreFilled,
    null,
    form
  );

  // Handle file upload changes
  const handleFileListChange = (newFileList) => {
    setFileList(newFileList);
  };

  // Handle photo upload changes
  const handlePhotoListChange = (newPhotoList) => {
    setPhotoList(newPhotoList);
  };

  // Handle switch between Target Price and Standard Quote
  const handleSwitchChange = (checked) => {
    setIsTargetPrice(checked);
  };

  // Handle login success and close the modal
  const handleLoginSuccess = () => {
    setIsLoginModalVisible(false);
  };

  // Form submission logic
  const onFinish = (values) => {
    if (!userInfo) {
      // If user is not logged in, show login modal
      message.error("Please login to continue.");
      setIsLoginModalVisible(true);
      return;
    }

    const submissionData = {
      ...values,
      user_id: userInfo.id,
      name: userInfo.name,
      customer: userInfo.name,
      incoterm: "-",
      paymentterm: "-",
      incoterm_description: "-",
      quotation_note: values.quotation_note || "-",
      delivery_date: "2024-02-25",
      status: "Pending Review",
      reference: values.requestNo || "-",
      filename: "-",
      target_price: isTargetPrice ? values.target_price : null,
    };

    // Call onSubmit to send the form data
    onSubmit(submissionData, fileList, photoList);
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish} className="get-quote-form">
        <Row justify="space-between" align="middle" className="form-header">
          <Col>
            <Title level={3} className="form-title">
              Upload Request
            </Title>
          </Col>
          <Col>
            <div className="quote-switch-container">
              <span className="quote-text">
                {isTargetPrice ? "Target Price" : "Standard Quote"}
              </span>
              <Switch
                checked={isTargetPrice}
                onChange={handleSwitchChange}
                className="quote-switch"
              />
            </div>
          </Col>
        </Row>

        <Form.Item>
          <FileUpload onFileListChange={handleFileListChange} />
        </Form.Item>

        {/* Kilit ikonu ve "All uploads secure and confidential" metni */}
        <div className="upload-security">
          <FontAwesomeIcon icon={faLock} className="lock-icon" />
          <span>All uploads are secure and confidential.</span>
        </div>

        <Form.Item
          name="quotation_note"
          label="Details"
          rules={[{ required: true, message: "Please enter your project/product description!" }]}
        >
          <Input.TextArea rows={4} placeholder="Please describe your project/product requirements" />
        </Form.Item>

        {isTargetPrice && (
          <Form.Item
            name="target_price"
            label="Target Price"
            rules={[{ required: true, message: "Please provide the target price!" }]}
          >
            <Input placeholder="Enter your target price" />
          </Form.Item>
        )}

        <Form.Item name="requestNo" label="Request No">
          <Input placeholder="Please provide your request number" />
        </Form.Item>

        <Form.Item
          name="productPhotos"
          label="Product Photos"
          extra="Packaging or in-built photos will help us to accelerate the quotation process."
        >
          <PhotoUpload onPhotoListChange={handlePhotoListChange} />
        </Form.Item>

        <Form.Item>
          <div className="submit-container">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>

      {/* Login modal that shows up if the user is not logged in */}
      <LoginModal
        visible={isLoginModalVisible}
        onCancel={() => setIsLoginModalVisible(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default GetQuoteForm;
