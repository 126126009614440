import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./process.css";

const Process = () => {
  return (
    <div className="process-container">
      <div className="process-step">
        <div className="icon-container">
          <img
            src="https://yenastorage.blob.core.windows.net/steelify/steelify_icon_get_quote.png"
            alt="Get Quote Icon"
          />
        </div>
        <p>Get Quote</p>
      </div>

      <FontAwesomeIcon icon={faArrowRight} className="process-arrow" />

      <div className="process-step">
        <div className="icon-container">
          <img
            src="https://yenastorage.blob.core.windows.net/steelify/steelify_icon_clearify_your_order.png"
            alt="Clearify Your Order Icon"
          />
        </div>
        <p>Order</p>
      </div>

      <FontAwesomeIcon icon={faArrowRight} className="process-arrow" />

      <div className="process-step">
        <div className="icon-container">
          <img
            src="https://yenastorage.blob.core.windows.net/steelify/steelify_icon_manucafacturing&quality.png"
            alt="Manufacturing & Quality Icon"
          />
        </div>
        <p>Manufacturing & Quality</p>
      </div>

      <FontAwesomeIcon icon={faArrowRight} className="process-arrow" />

      <div className="process-step">
        <div className="icon-container">
          <img
            src="https://yenastorage.blob.core.windows.net/steelify/steelify_icon_track_shipment.png"
            alt="Track Shipment Icon"
          />
        </div>
        <p>Track Shipment</p>
      </div>
    </div>
  );
};

export default Process;
