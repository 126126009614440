import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./networkMap.css";

const customerIcon = new L.Icon({
  iconUrl: "https://yenastorage.blob.core.windows.net/steelify/steelify_icon_location_marker_FF6F00.png",
  iconSize: [25, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const supplierIcon = new L.Icon({
  iconUrl: "https://yenastorage.blob.core.windows.net/steelify/steelify_icon_location_marker_0E6AED.png",
  iconSize: [25, 25],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const customers = [
  { lat: 51.5074, lng: -0.1278, name: "London", count: 1 }, // Londra
  { lat: 55.6761, lng: 12.5683, name: "Copenhagen", count: 1 }, // Kopenhag
  { lat: 60.1695, lng: 24.9354, name: "Helsinki", count: 1 }, // Helsinki (Finlandiya)
  { lat: 52.3676, lng: 4.9041, name: "Amsterdam", count: 1 }, // Amsterdam (Hollanda)
  { lat: 51.9244, lng: 4.4777, name: "Rotterdam", count: 1 }, // Rotterdam (Hollanda)
  { lat: 51.4416, lng: 5.4697, name: "Eindhoven", count: 1 }, // Eindhoven (Hollanda)
  { lat: 50.8503, lng: 4.3517, name: "Brussels", count: 1 }, // Brüksel (Belçika)
  { lat: 51.2194, lng: 4.4025, name: "Antwerp", count: 1 }, // Antwerp (Belçika)
  { lat: 52.52, lng: 13.405, name: "Berlin", count: 1 }, // Berlin (Almanya)
  { lat: 50.9375, lng: 6.9603, name: "Cologne", count: 1 }, // Köln (Almanya)
  { lat: 51.2277, lng: 6.7735, name: "Düsseldorf", count: 1 }, // Düsseldorf (Almanya)
  { lat: 48.7758, lng: 9.1829, name: "Stuttgart", count: 1 }, // Stuttgart (Almanya)
  { lat: 51.5136, lng: 7.4653, name: "Dortmund", count: 1 }, // Dortmund (Almanya)
  { lat: 53.5511, lng: 9.9937, name: "Hamburg", count: 1 }, // Hamburg (Almanya)
  { lat: 48.8566, lng: 2.3522, name: "Paris", count: 1 }, // Paris (Fransa)
  { lat: 43.6045, lng: 1.444, name: "Toulouse", count: 1 }, // Toulouse (Fransa)
  { lat: 41.9028, lng: 12.4964, name: "Rome", count: 1 }, // Roma (İtalya)
  { lat: 41.3348, lng: -8.4795, name: "Santo Tirso", count: 1 }, // Santo Tirso (Portekiz)
  { lat: 54.5245, lng: -1.5581, name: "Darlington", count: 1 }, // Darlington (İngiltere)
];

const suppliers = [
  { lat: 41.0082, lng: 28.9784, name: "Istanbul", count: 17 }, // İstanbul
  { lat: 39.9334, lng: 32.8597, name: "Ankara", count: 11 }, // Ankara
  { lat: 38.4237, lng: 27.1428, name: "Izmir", count: 6 }, // İzmir
  { lat: 40.7669, lng: 30.3948, name: "Sakarya", count: 4 }, // Sakarya
  { lat: 40.7654, lng: 29.9407, name: "Kocaeli", count: 4 }, // İzmit
  { lat: 40.1828, lng: 29.0669, name: "Bursa", count: 5 }, // Bursa
  { lat: 39.7767, lng: 30.5206, name: "Eskisehir", count: 2 }, // Eskişehir
  { lat: 37.0000, lng: 35.3213, name: "Adana", count: 1 }, // Adana
  { lat: 37.8667, lng: 32.4833, name: "Konya", count: 2 }, // Konya
  { lat: 50.2649, lng: 19.0238, name: "Katowice", count: 1 }, // Polonya (Katowice)
  { lat: 50.0647, lng: 19.9450, name: "Krakow", count: 1 }, // Polonya (Kraków)
  { lat: 51.1079, lng: 17.0385, name: "Wroclaw", count: 1 }, // Polonya (Wrocław)
  { lat: 42.6977, lng: 23.3219, name: "Sofia", count: 1 }, // Bulgaristan (Sofya)
  { lat: 44.4268, lng: 26.1025, name: "Bucharest", count: 1 }, // Romanya (Bükreş)
  { lat: 45.4350, lng: 28.0079, name: "Galati", count: 1 }, // Romanya (Galați)
  { lat: 49.8209, lng: 18.2625, name: "Ostrava", count: 1 }, // Çekya (Ostrava)
];

const bounds = [
  [65, -10], // Southwest corner
  [30, 50],  // Northeast corner
];

const NetworkMap = () => {
  const [viewport, setViewport] = useState({
    latitude: 48,
    longitude: 32.8541,
    zoom: 5,
    width: "100%",
    height: "700px",
  });

  return (
    <div className="map-container">
      <h2 className="map-title">Network Map</h2>
      <MapContainer
        center={[viewport.latitude, viewport.longitude]}
        zoom={viewport.zoom}
        maxZoom={8}
        minZoom={3}
        style={{ height: viewport.height, width: viewport.width }}
        scrollWheelZoom={false}
        maxBounds={bounds}
      >
        <TileLayer
          url="https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=e4eb8281d81f4272935288e009ec5b53"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://www.thunderforest.com/maps/transport/">Thunderforest</a>'
        />

        {customers.map((customer, index) => (
          <Marker key={index} position={[customer.lat, customer.lng]} icon={customerIcon}>
            <Popup>
              {customer.name}: {customer.count}
            </Popup>
          </Marker>
        ))}

        {suppliers.map((supplier, index) => (
          <Marker key={index} position={[supplier.lat, supplier.lng]} icon={supplierIcon}>
            <Popup>
              {supplier.name}: {supplier.count}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <div className="legend">
        <div className="legend-item">
          <span className="legend-color customers"></span>
          Customers
        </div>
        <div className="legend-item">
          <span className="legend-color suppliers"></span>
          Suppliers
        </div>
      </div>
    </div>
  );
};

export default NetworkMap;
